import { Component, Input, Optional, Inject, ViewChild } from '@angular/core';
import { NgModel, NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';
import 'rxjs'

import { ElementBase } from '../element-base/element-base';

@Component({
    selector: 'form-money',
    templateUrl: "./form-money.component.html",
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: FormMoneyComponent, multi: true }
    ],
})
export class FormMoneyComponent extends ElementBase<number> {
    constructor(
        @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
        @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>
    ) {

        super(validators, asyncValidators);
    }

    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public allowNegative?: boolean = false;
    @Input() public currency?: string = 'R$';
    @Input() public disabled?: boolean;

    precision: number = 2
    decimalSeparetor: string = ',';
    thousandsSeparator: string = '.';
    prefix: string = '';

    public identifier = `form-money-${identifier++}`;

    @ViewChild(NgModel, { static: true }) model: NgModel;
}

let identifier = 0;
