/*
 ####### Componente de Autocomplete Genérico utilizando Angular v6+ e PrimeNG #######
   #Implementado em 05/08/2019 por:
   - Gustavo Arruda (Vermeio)
   - Ricardo Abe
  *Descrição dos parâmetros do componente:
   #INPUT:
   - Label: Título do componente 
   - Placeholder: Palavra ou frase cujo intuito seja ajudar a entender como preencher o componente
   - Required: Controle de validação de campo obrigatório (default: false)
   - Disabled: Controle de desabilitar o campo
   - DataSource: Localização da fonte de dados informando a url (endpoint), o nome do controller e a action (caso existir). Ex: 'http://localhost:4200/api/values'
   - DisplayField: Campo/atributo do objeto a ser exibido nas sugestões
   - ValueField: Campo/atributo ao ser retornado ao selecionar uma opção
   - Value: Valor de entrada (property data binding) para selecionar uma opção
   - QueryArgName: Nome do argumento para o parâmetro de consulta (default: q)
   - IdArgName: Nome do argumento para o parâmetro de id (default: id)
   #OUTPUT
   - ValueChange: Emissor de eventos de saída (event binding) para 'Value' disparando o valor do campo/atributo informado ao selecionar ou deselecionar uma opção
   - ObjectChange: Emissor de eventos disparando o objeto ao selecionar ou deselecionar uma opção
  *Exemplos de uso:
   #1
   <autocomplete label="Exemplo1"
                 dataSource="url | dataSource"
                 displayField="nome" valueField="id"
                 [(value)]="model.id">
   </autocomplete>
   #2
   <autocomplete label="Exemplo2"
                 dataSource="url | dataSource"
                 displayField="nome" valueField="id"
                 (objectChange)="model = $event">
   </autocomplete>
   #3
   <autocomplete label="Exemplo3"
                 dataSource="url | dataSource"
                 displayField="nome" valueField="id"
                 [(value)]="model.id" (objectChange)="model = $event">
   </autocomplete>
 */
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize } from 'rxjs/operators';

import { AutocompleteService } from './autocomplete.service';
import { AutoCompleteDataSource } from './autocomplete';

@Component({
  selector: 'autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css'],
  providers: [
    AutocompleteService
  ]
})
export class AutocompleteComponent implements OnInit {

  @Input() label: string;
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() dataSource: any;
  @Input() displayField: string;
  @Input() valueField: string;
  @Input() value: string;
  @Output() valueChange: EventEmitter<string>;
  @Output() objectChange: EventEmitter<any>;
  _model: any;
  _filteredData: any[];
  _errorMessage: string;
  _componenteInicializado: boolean;
  _identifier = `autocomplete-${identifier++}`;

  @Input()
  set queryArgName(value: string) {
    this._service._queryArgName = value;
  }
  get queryArgName(): string {
    return this._service._queryArgName;
  }
  @Input()
  set idArgName(value: string) {
    this._service._idArgName = value;
  }
  get idArgName(): string {
    return this._service._idArgName;
  }

  ngOnInit() {
    this.search(null);
  }

  constructor(private _service: AutocompleteService) {
    this.required = false;
    this.disabled = false;
    this._componenteInicializado = false;
    this.valueChange = new EventEmitter();
    this.objectChange = new EventEmitter();
    this._service._queryArgName = 'q';
    this._service._idArgName = 'id';
  }

  search(value): void {
    if (this.isAutoCompleteDataSource(this.dataSource)) {
      //A implementar
    }
    else if (typeof (this.dataSource) == 'object') {
      if (!value) this._filteredData = this.dataSource;
      else this._filteredData = this.dataSource.filter(d => d.nome.toLowerCase().indexOf(value.query.toLowerCase()) == 0);
      if (!this._componenteInicializado && !this._model && this.value) {
        this._model = this.dataSource.find(d => d.id == this.value);
        this._componenteInicializado = true;
      }
    }
    else if (typeof (this.dataSource) == 'string') {
      this._service._url = this.dataSource;
      this._service.find(value ? value.query : null, this.value)
        .pipe(finalize(() => this._componenteInicializado = true))
        .subscribe({
          next: (data: any) => {
            if (data.items && data.items.length) {
              if (!this._componenteInicializado && !this._model && this.value) {
                this._model = data.items.find(d => d.id == this.value);
              }
              this._filteredData = data.items;
            } else if (data && data.length) {
              if (!this._componenteInicializado && !this._model && this.value) {
                this._model = data.find(d => d.id == this.value);
              }
              this._filteredData = data;
            } else {
              this._filteredData = [];
            }
          },
          error: (error: HttpErrorResponse) => {
            this._filteredData = [];
            this._errorMessage = error.error;
          }
        });
    }
  }
  onChanged(item): void {
    if (!item) {
      this.valueChange.emit('');
      this.objectChange.emit('');
    }
    else if (!this.valueField) {
      this.objectChange.emit(item);
    }
    else {
      var key = Object.keys(item).findIndex(k => k == this.valueField) as number;
      var value = Object.values(item)[key] as string;
      this.valueChange.emit(value);
      this.objectChange.emit(item);
    }
  }
  isAutoCompleteDataSource(object: any): object is AutoCompleteDataSource {
    //A implementar
    return false;
  }
}
let identifier = 0;
