import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AppContext {

  constructor() { }

  private substituidoSelecionado = new BehaviorSubject<Usuario>(null);

  getUsuarioSelecionado(): Usuario {
    return this.substituidoSelecionado.getValue();
  }

  setUsuarioSelecionado(param: Usuario) {
    if (param && this.substituidoSelecionado.getValue() && param.id === this.substituidoSelecionado.getValue().id)
      return; //n seta pq n mudou

    this.substituidoSelecionado.next(param);
  }

  onUsuarioSelecionadoChanged(): Observable<Usuario> {
    return this.substituidoSelecionado.asObservable();
  }
}

export class Usuario {
  id: string;
  name: string;
  representanteId: string;
}
