import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AutoCompleteDataSource } from './autocomplete';
import { AuthService } from '../../../services/authentication/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AutocompleteService implements AutoCompleteDataSource {

  _url: string;
  _queryArgName: string;
  _idArgName: string;

  constructor(private authService: AuthService) { }

  find(q: string, id?: string): Observable<any> {
    let params = new HttpParams();
    params = params.set(this._queryArgName, q);
    if (id) params = params.set(this._idArgName, id);
    return this.authService.get(`${this._url}`, params);
  }

}
