import { Component, Input, Optional, Inject, ViewChild, OnInit, EventEmitter, Output } from '@angular/core';
import { NgModel, NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';
import 'rxjs'

import { ElementBase } from '../element-base/element-base';

@Component({
  selector: 'form-month',
  templateUrl: "./form-month.component.html",
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FormMonthComponent, multi: true }
  ],
})
export class FormMonthComponent extends ElementBase<string> {
  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>
  ) {

    super(validators, asyncValidators);
  }

  @Input() public label: string;
  @Input() public placeholder: string;
  @Input() public startDate: Date = new Date();
  @Input() public submitted: boolean = false;
  @Output() public dataAlterada = new EventEmitter<Date>();

  meses: string[] = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
  ];
  anos: number[] = [];

  private anoSelecionado: number;
  private mesSelecionado: string;

  public identifier = `form-month-${identifier++}`;

  @ViewChild(NgModel) model: NgModel;

  ngOnInit() {
    for (let i = 1900; i <= this.startDate.getFullYear(); i++) {
      this.anos.push(i);
    }
  }

  onChange() {

    if (this.mesSelecionado && this.mesSelecionado.length > 0 && this.anoSelecionado && this.anoSelecionado != 0) {
      let data: Date = new Date();
      data.setMonth(this.meses.indexOf(this.mesSelecionado));
      data.setFullYear(this.anoSelecionado);
      data.setDate(0);

      this.dataAlterada.next(null);
    }
  }
}

let identifier = 0;
