import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { AppContext } from '../../app-context';
import { AuthService } from '../../services/authentication/auth.service';

@Component({
  selector: 'opt-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  @Input() usuario: any;

  constructor(private _authService: AuthService, private _appContext: AppContext) { }

  ngOnInit() {
    this.loadSubstituido();
  }

  alterarSenhaUsuario() {
    window.open(`${this._authService.getStsUrl()}/Account/Manage/ChangePassword`);
  }

  sair() {
    this._authService.get('profile/cache', new HttpParams().set('identityId', this.usuario.sub))
      .pipe(finalize(() => this._authService.signOut()))
      .subscribe({
        next: () => { },
        error: (error) => console.log('error', error)
      });
  }

  readonly substituidosUrl = 'profile/substituidos';
  substituido = [];
  substituidoLoading = false;
  substituidoInput$ = new Subject<string>();
  selectedSubstituido: any;
  showSubstituidos: boolean;

  trackByFn(item: any) {
    return item.id;
  }

  private loadSubstituido() {
    this._authService.get(this.substituidosUrl)
      .pipe(
        tap(() => this.substituidoLoading = true),
        finalize(() => this.substituidoLoading = false))
      .subscribe({
        next: (result) => {
          this.substituido = result;
          this.showSubstituidos = true;
          this.selectedSubstituido = result[0];
        },
        error: (error) => { }
      })
  }

  onChangeSubstituido(substituido) {
    this._appContext.setUsuarioSelecionado(substituido);
  }
}
