import { Component, Input, Optional, Inject, ViewChild } from '@angular/core';
import { NgModel, NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';
import 'rxjs'

import { ElementBase } from '../element-base/element-base';

@Component({
    selector: 'form-phone',
    templateUrl: "./form-phone.component.html",
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: FormPhoneComponent, multi: true }
    ],
})
export class FormPhoneComponent extends ElementBase<string> {
    constructor(
        @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
        @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>
    ) {

        super(validators, asyncValidators);
    }

    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public clearIfIncomplete: boolean = true;
    @Input() public disabled: boolean = false;

    mask: string = '(99) 9999-99999';

    public identifier = `form-phone-${identifier++}`;

    @ViewChild(NgModel, { static: true }) model: NgModel;

    onKeyUp($event: any) {

        let valor = $event.target.value.replace(/\D/g, '');

        if (valor.length <= 10) {
            this.mask = '(99) 9999-99999';
        } else {
            this.mask = '(99) 99999-9999';
        }

        let pad = this.mask.replace(/\D/g, '').replace(/9/g, '_');
        let valorMask = valor + pad.substring(0, pad.length - valor.length);

        // retorna caso pressionado backspace
        if ($event.keyCode === 8) {
            return;
        }

        var valorMaskPos = 0;
        valor = '';

        for (var i = 0; i < this.mask.length; i++) {
            if (isNaN(parseInt(this.mask.charAt(i)))) {
                valor += this.mask.charAt(i);
            } else {
                valor += valorMask[valorMaskPos++];
            }
        }

        if (valor.indexOf('_') > -1) {
            valor = valor.substr(0, valor.indexOf('_'));
        }

        $event.target.value = valor;
        this.value = valor;
    }

    onBlur($event: any) {

        if ($event.target.value.length >= this.mask.length - 1) {

            this.onKeyUp($event);
            return;
        }

        $event.target.value = '';
    }
}

let identifier = 0;
