<header id="header" class="page-navbar header-fixed p-0">
  <div class="row">
    <div class="col-lg-3 col-md-4 col-sm-2 col-xs-1 size-small-screen">
      <ul class="nav navbar-nav" style="margin-right: -50px;">
        <li class="toggle-sidebar">
          <span class="color-w menu-toggle cursor-pointer" (click)="collapse=!collapse">
            <i class="fa fa-reorder"></i>
            <span class="sr-only">Collapse sidebar</span>
          </span>
        </li>
      </ul>
      <div style="margin-top: -50px;" class="d-none d-lg-block">
        <opt-banner bannerUrl="assets/img/banner-dislub.png"></opt-banner>
      </div>
    </div>
    <div class="col user-container">
      <opt-user [usuario]="usuario"></opt-user>
    </div>
  </div>
</header>
<div class="container-fluid">
  <div class="row">
    <opt-menu [menus]="menu" [collapse]="collapse"></opt-menu>
    <main class="page-content clearfix pr-0" [class.sidebar-page]="true" [ngClass]="{'collapsed-sidebar': collapse, 'sidebar-page': !collapse}">
    <!-- <div class="page-content clearfix" [class.sidebar-page]="true" [ngClass]="{'collapsed-sidebar': collapse, 'sidebar-page': !collapse}"> -->
      <div class="page-content-wrapper">
        <div class="page-content-inner px-0">
          <router-outlet></router-outlet>
        </div>
      </div>
    </main>
  </div>
</div>
