<!-- Vertical Menu -->
<div id="wrapper">
  <aside id="sidebar" class="page-sidebar" style="position: fixed !important"
    [ngClass]="{'collapse-sidebar': collapse}">
    <div class="sidebar-inner">
      <div class="sidebar-scrollarea">

        <!-- #region ITENS MENU -->

        <!-- INPUT SEARCH -->
        <div *ngIf="!collapse" id="search-menu" class="d-none d-lg-block">
          <form class="form-horizontal">
            <div class="sidebar-panel-content d-none d-lg-block">
              <div class="form-group">
                <div class="input-serach input-group input-icon m-0" id="input-filter">
                  <span class="input-group-addon"><i class="fa fa-search"
                      style="margin-top: -4px; margin-right:0.5rem"></i></span>
                  <input id="input-search" type="text" name="pesquisar" class="form-control" placeholder="Pesquisar"
                    [(ngModel)]="pesquisaMenu" />
                </div>
              </div>
            </div>
          </form>
        </div>

        <div [hidden]="pesquisaMenu" class="side-nav">
          <ul class="nav">
            <li *ngFor="let menuItem of menus; let idx = index">

              <!-- BTN MENU LINKS SEM LINK EXTERNO -->
              <ng-container *ngIf="!menuItem.linkExterno">

                <!-- SEM SUBMENUS -->
                <ng-container *ngIf="!menuItem.submenu">
                  <a [routerLink]="menuItem.rota ? [menuItem.rota] : []" class="btn btn-link text-start px-2"
                    (click)="setMenuOpen(idx, $event)" href="#home-{{idx+1}}" data-bs-toggle="collapse" role="button"
                    aria-controls="home-{{idx+1}}">
                    <i [class]="menuItem.icone"></i><span class="txt">{{menuItem.descricao}}</span>
                  </a>
                </ng-container>

                <!-- COM SUBMENUS -->
                <ng-container *ngIf="menuItem.submenu">
                  <!-- MENU ABERTO -->
                  <ng-container *ngIf="!collapse">
                    <a class="btn btn-link text-start px-2" href="#home-{{idx+1}}" data-bs-toggle="collapse"
                      role="button" (click)="setMenuOpen(idx, $event)" aria-controls="home-{{idx+1}}">
                      <i class="l-arrows-right sideNav-arrow" [ngClass]="{'rotate90': menu.open == idx}"></i>
                      <i [class]="menuItem.icone"></i><span class="txt">{{menuItem.descricao}}</span>
                    </a>
                    <div class="collapse" id="home-{{idx+1}}">
                      <ul class="sub" style="display: block">
                        <li *ngFor="let submenuItem of menuItem.submenu">
                          <!-- SEM LINK EXTERNO -->
                          <ng-container *ngIf="!submenuItem.linkExterno">
                            <a href="#" [routerLink]="submenuItem.rota ? [submenuItem.rota] : []"
                              [routerLinkActive]="'active'">
                              <span class="txt">{{submenuItem.descricao}}</span>
                            </a>
                          </ng-container>
                          <!-- COM LINK EXTERNO -->
                          <ng-container *ngIf="submenuItem.linkExterno">
                            <a [attr.href]="submenuItem.linkExterno" [routerLinkActive]="'active'">
                              <span class="txt">{{submenuItem.descricao}}</span>
                            </a>
                          </ng-container>
                        </li>
                      </ul>
                    </div>
                  </ng-container>

                  <!-- MENU FECHADO -->
                  <ng-container *ngIf="collapse">
                    <div class="btn-group dropend" style="width: 100%;">

                      <!-- <a class="btn btn-link text-start px-2" id="home-{{idx+1}}" class="btn btn-link btn-menu-fechado"
                        (click)="setMenuOpen(idx, $event)">
                        <i [class]="menuItem.icone"></i>
                      </a> -->

                      <button type="button" id="home-{{idx+1}}" class="btn btn-link btn-menu-fechado"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <i [class]="menuItem.icone"></i>
                      </button>

                      <ul class="dropdown-menu submenu-collapsed" aria-labelledby="home-{{idx+1}}">
                        <li class="titulo-submenu">
                          <span>
                            {{menuItem.descricao}}
                          </span>
                        </li>
                        <li *ngFor="let submenuItem of menuItem.submenu" class="submenu-itens">
                          <!-- SEM LINK EXTERNO -->
                          <ng-container *ngIf="!submenuItem.linkExterno">
                            <a href="#" [routerLink]="submenuItem.rota ? [submenuItem.rota] : []"
                              [routerLinkActive]="'active'" class="text-start py-2 text-decoration-none">
                              {{submenuItem.descricao}}
                            </a>
                          </ng-container>
                          <!-- COM LINK EXTERNO -->
                          <ng-container *ngIf="submenuItem.linkExterno">
                            <a [attr.href]="submenuItem.linkExterno" [routerLinkActive]="'active'"
                              class="text-start py-2 text-decoration-none">
                              {{submenuItem.descricao}}
                            </a>
                          </ng-container>
                        </li>
                      </ul>
                    </div>
                  </ng-container>

                </ng-container>
              </ng-container>

              <!-- BTN MENU LINKS COM LINK EXTERNO -->
              <ng-container *ngIf="menuItem.linkExterno">
                <a class="btn btn-link text-start" data-bs-toggle="collapse" href="#home-{{idx+1}}" role="button">
                  <i [class]="menuItem.icone"></i><span class="txt">{{menuItem.descricao}}</span>
                </a>
              </ng-container>

              <!-- SUBMENU -->
              <!-- <ng-container *ngIf="menuItem.submenu ">
                MENU ABERTO
                <ng-container *ngIf="!collapse">
                  <div class="collapse" id="home-{{idx+1}}">
                    <ul class="sub" style="display: block">
                      <li *ngFor="let submenuItem of menuItem.submenu">
                        SEM LINK EXTERNO
                        <ng-container *ngIf="!submenuItem.linkExterno">
                          <a href="#" [routerLink]="submenuItem.rota ? [submenuItem.rota] : []"
                            [routerLinkActive]="'active'"><span class="txt">{{submenuItem.descricao}}</span></a>
                        </ng-container>
                        COM LINK EXTERNO
                        <ng-container *ngIf="submenuItem.linkExterno">
                          <a [attr.href]="submenuItem.linkExterno" [routerLinkActive]="'active'"><span
                              class="txt">{{submenuItem.descricao}}</span></a>
                        </ng-container>
                      </li>
                    </ul>
                  </div>
                </ng-container>

                MENU FECHADO
                <ng-container *ngIf="collapse">
                  <div class="btn-group dropend">
                    <ul class="dropdown-menu" aria-labelledby="home-{{idx+1}}">
                      <li *ngFor="let submenuItem of menuItem.submenu">
                        SEM LINK EXTERNO
                        <ng-container *ngIf="!submenuItem.linkExterno">
                          <a href="#" [routerLink]="submenuItem.rota ? [submenuItem.rota] : []"
                            [routerLinkActive]="'active'"><span class="txt">{{submenuItem.descricao}}</span></a>
                        </ng-container>
                        COM LINK EXTERNO
                        <ng-container *ngIf="submenuItem.linkExterno">
                          <a [attr.href]="submenuItem.linkExterno" [routerLinkActive]="'active'"><span
                              class="txt">{{submenuItem.descricao}}</span></a>
                        </ng-container>
                      </li>
                    </ul>
                  </div>
                </ng-container>
              </ng-container> -->

            </li>
          </ul>
        </div>

        <!-- #endRegion ITENS MENU -->

        <!-- Início pesquisa menu -->
        <div *ngIf="pesquisaMenu" class="search-nav">
          <ul class="nav">
            <li *ngFor="let menuItem of menus | filtroMenu: pesquisaMenu" class="highlight-menu hasSub">
              <ng-container *ngIf="!menuItem.linkExterno">
                <a [class.active]="menuItem.ativo" [routerLink]="menuItem.rota ? [menuItem.rota] : []" class="expand"><i
                    [class]="menuItem.icone"></i><span class="txt">{{menuItem.descricao}}</span></a>
              </ng-container>
              <ng-container *ngIf="menuItem.linkExterno">
                <a [attr.href]="menuItem.linkExterno" [class.active]="menuItem.ativo" class="expand"><i
                    [class]="menuItem.icone"></i><span class="txt">{{menuItem.descricao}}</span></a>
              </ng-container>
              <ul *ngIf="menuItem.submenu" class="sub show" style="display: block">
                <li *ngFor="let submenuItem of menuItem.submenu | filtroSubmenu: pesquisaMenu: menuItem">
                  <ng-container *ngIf="!submenuItem.linkExterno">
                    <a href="#" [routerLink]="submenuItem.rota ? [submenuItem.rota] : []"
                      [routerLinkActive]="'active'"><span class="txt">{{submenuItem.descricao}}</span></a>
                  </ng-container>
                  <ng-container *ngIf="submenuItem.linkExterno">
                    <a [attr.href]="submenuItem.linkExterno" [routerLinkActive]="'active'"><span
                        class="txt">{{submenuItem.descricao}}</span></a>
                  </ng-container>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <!-- Fim pesquisa menu -->
      </div>
    </div>
  </aside>
</div>