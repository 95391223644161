import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { ProfileService } from '../profile.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileGuard implements CanActivate {

  constructor(
    private _profileService: ProfileService,
    private _router: Router
  ) { }

  canActivate() {
    return this.checkProfile();
  }

  async checkProfile() {
    const hasProfile = this._profileService.getIsAdmin() || (await this._profileService.getIsConsultor());

    if (!hasProfile) {
      this._router.navigate(['/unauthorized']);
    }

    return hasProfile;
  }
}
