import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { TranslateService } from "@ngx-translate/core";

import { AuthService } from '../../services/authentication/auth.service'
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.css']
})
export class PublicLayoutComponent implements OnInit {

  usuario: any;

  constructor(private _authService: AuthService) {    
  }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.usuario = this._authService.getUserData();
  }

  changePassword() {
    window.open(`${this._authService.getStsUrl()}/Account/Manage/ChangePassword`);
  }

  signOut() {
    this._authService.get('profile/cache', new HttpParams().set('identityId', this.usuario.sub))
      .pipe(finalize(() => this._authService.signOut()))
      .subscribe({
        next: () => { },
        error: (error) => console.log('error', error)
      });
  }
}
