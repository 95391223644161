import { Component, Input, Optional, Inject, ViewChild } from '@angular/core';
import { NgModel, NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';
import 'rxjs'

import { ElementBase } from '../element-base/element-base';

@Component({
  selector: 'form-radio',
  templateUrl: "./form-radio.component.html",
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FormRadioComponent, multi: true }
  ],
})
export class FormRadioComponent extends ElementBase<string> {
  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>
  ) {

    super(validators, asyncValidators);
  }

  @Input() public controlName: string;
  @Input() public controlText: string;
  @Input() public controlValue: string;
  @Input() public disabled?: boolean;
  @Input() public checkedRadio?: boolean;

  public identifier = `form-radio-${identifier++}`;

  @ViewChild(NgModel, { static: true }) model: NgModel;
}

let identifier = 0;

class RadioItem {
  key: number;
  value: string;
}
