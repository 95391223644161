<div class="row align-items-center justify-content-end">
  <div class="col-12" style=" display: flex; align-items: center; gap: 2rem; height: 50px; justify-content: flex-end;">
    <div class="d-flex align-items-center" *ngIf="showSubstituidos">
      <span class="me-2">Usuário ativo</span>
      <ng-select name="substituidos" [items]="substituido" bindLabel="name"
                 [loading]="substituidoLoading" typeToSearchText="Digite a razão social"
                 [(ngModel)]="selectedSubstituido" notFoundText="Nenhum usuário encontrado"
                 (change)="onChangeSubstituido($event)" loadingText="Carregando..." clearAllText="Limpar" class="ng-select-custom"
                 title="Usuários substituindo">
      </ng-select>

    </div>
    <div class="dropdown pe-2">
      <span class="hidden-xs hidden-sm hidden-md">{{ usuario?.name }}</span>
      <button class="btn dropdown-toggle" id="btnUser" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa fa-user-circle ml5" style="font-size: 20px; float:none;" id="users"></i>
        <span class="sr-only">Usuário</span>
      </button>
      <ul class="dropdown-menu" aria-labelledby="btnUser">
        <li>
          <a class="cursor-pointer dropdown-item" (click)="alterarSenhaUsuario()"><i class="fa fa-key"></i> Alterar senha</a>
        </li>
        <li>
          <a class="cursor-pointer dropdown-item" (click)="sair()"><i class="fa fa-power-off"></i> Sair</a>
        </li>
      </ul>
    </div>
  </div>
</div>
