import { Injectable } from '@angular/core';

@Injectable()
export class UrlService {

    getObjectFromQueryString(queryString: string) {

        let data: { [key: string]: string } = {};
        let pairs: string[];
        let pair: string;
        let separatorIndex: number;
        let escapedKey: string;
        let escapedValue: string;
        let key: string;
        let value: string;

        if (queryString === null) {
            return data;
        }

        queryString = queryString.replace('?', '');

        pairs = queryString.split("&");

        for (var i = 0; i < pairs.length; i++) {
            pair = pairs[i];
            separatorIndex = pair.indexOf("=");

            if (separatorIndex === -1) {
                escapedKey = pair;
                escapedValue = null;
            } else {
                escapedKey = pair.substr(0, separatorIndex);
                escapedValue = pair.substr(separatorIndex + 1);
            }

            key = decodeURIComponent(escapedKey);
            value = decodeURIComponent(escapedValue);

            data[key] = value;
        }

        return data;
    }

    getQueryStringFromObject(object: any) {

        let queryString: string = "";

        for (var key in object) {
            if (queryString != "") {
                queryString += "&";
            }
            queryString += `${key}=${encodeURIComponent(object[key])}`;
        }

        return queryString;
    }
}