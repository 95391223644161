import { Component, Input, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.css'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: '*'
        })
      ),
      state(
        'closed',
        style({
          height: '0px'
        })
      ),
      transition('open => closed', [animate('300ms ease-out')]),
      transition('closed => open', [animate('300ms ease-out')]),
    ]),
  ],
})
export class SubMenuComponent implements OnInit {
  @Input('open') open;
  @Input('submenu') submenu;
  @Input('menu') menu;
  _collapse;
  get collapse() {
    return this._collapse;
  }
  @Input('collapse') set collapse(value) {
    setTimeout(() => this._collapse = value, 300);//para contornar bug na animação no toggle do menu
  }

  constructor() { }

  ngOnInit() { }

  setMenuOpen(idx, e) {
    e.stopPropagation();
    let menuOpen = this.menu.open;
    this.menu.open = menuOpen == -1 || menuOpen != idx ? idx : -1;
    this.menu.submenu = {};
  }
}
