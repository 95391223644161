<div class="form-group" [ngClass]="{'has-error': (model.control.touched && invalid  | async)}">
    <label class="form-label" *ngIf="label" [attr.for]="identifier" class="control-label">{{label}}</label>
    <!--<span *ngIf="validators[0]._required">*</span>-->

    <div class="input-group">
        <span class="input-group-addon">{{currency}}</span>
        <input currencyMask type="text" [id]="identifier" class="form-control" [(ngModel)]="value" [attr.placeholder]="placeholder ? placeholder : null" [disabled]="disabled"
               [options]="{ prefix: prefix, thousands: thousandsSeparator, decimal: decimalSeparetor, precision: precision, allowNegative: allowNegative }" />
    </div>

    <validation-messages *ngIf="model.control.touched && invalid | async" [messages]="failures | async">
    </validation-messages>
</div>