<div class="form-group" [ngClass]="{'has-error': ((submitted || model.control.touched) && invalid  | async)}">
    <label class="form-label" *ngIf="label" [attr.for]="identifier" class="control-label">{{label}}</label>
    <div class="input-group">
      <span class="input-group-addon" id="month-addon">Mês: </span>
      <select name="control-month" class="form-control" aria-describedby="month-addon">
        <option *ngFor="let mes of meses">{{mes}}</option>
      </select>
      <span class="input-group-addon" id="year-addon">Year: </span>
      <select name="control-year" class="form-control" aria-describedby="year-addon">
        <option *ngFor="let ano of anos">{{ano}}</option>
      </select>
    </div>

    <validation-messages *ngIf="(submitted || model.control.touched) && invalid | async" [messages]="failures | async">
    </validation-messages>
</div>
