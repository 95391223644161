import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Router } from '@angular/router';
import { Observable } from "rxjs";

import { UserManager, User } from 'oidc-client';
import { AppContext } from "../../app-context";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiBaseUrl = `${window.location.origin}/api/`;

  public static manager: UserManager;
  private _user: User = null;
  private _profile: any = null;

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _appContext: AppContext,
    private _toastService: ToastrService
  ) {
    AuthService.manager.getUser().then(async user => {
      this._user = user;
      if (user) {
        await this.getProfile();
      }
    });
  }

  completeAuthentication() {
    return AuthService.manager.signinRedirectCallback().then(async user => {
      this._user = user;
      this._profile = await this.getProfile();
      this._router.navigate(['/']);
    });
  }

  getIsAuthorized() {
    return this._user != null && !this._user.expired;
  }

  getUserData() {
    return this._user ? this._user.profile : null;
  }

  async getProfile() {
    if (!this._profile) {
      await this.get('profile').toPromise()
        .then(profile => this._profile = profile)
        .catch((reason) => {
          //Para não apresentar exceções no console
          console.error(reason);
          console.error("Erro ao obter perfil do usuário");
          this._toastService.error("Erro ao obter perfil do usuário");
        });
    }

    return this._profile;
  }

  async updateProfile() {
    await this.get('profile').toPromise()
      .then(profile => this._profile = profile)
      .catch(() => {
        //Para não apresentar exceções no console
        console.log('erro ao atualizar profile')
      });
  }

  get(path: string, params?: HttpParams): Observable<any> {
    return this._http.get(this.apiBaseUrl + path, { headers: this.getHeaders(), params: params });
  }

  put(path: string, data: any, params?: HttpParams): Observable<any> {
    const body = JSON.stringify(data);
    return this._http.put(this.apiBaseUrl + path, body, { headers: this.getHeaders(), params: params });
  }

  delete(path: string, params?: HttpParams): Observable<any> {
    return this._http.delete(this.apiBaseUrl + path, { headers: this.getHeaders(), params: params });
  }

  post(path: string, data: any, params?: HttpParams): Observable<any> {
    const body = JSON.stringify(data);
    return this._http.post(this.apiBaseUrl + path, body, { headers: this.getHeaders(), params: params });
  }

  signIn() {
    AuthService.manager.signinRedirect();
  }

  signOut() {
    AuthService.manager.signoutRedirect();
  }

  getStsUrl() {
    return AuthService.manager.settings.authority;
  }

  private getHeaders() {
    let headers = new HttpHeaders();
    headers = this.appendAuthHeader(headers);
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Accept', 'application/json');
    var user = this._appContext.getUsuarioSelecionado();
    if (user) headers = headers.set('X-Covered', user.id);//coberto, substituto
    return headers;
  }

  private appendAuthHeader(headers: HttpHeaders) {
    const token = this._user.access_token;
    if (token === '') return headers;
    const tokenValue = `${this._user.token_type} ${token}`;
    return headers.set('Authorization', tokenValue);
  }
  getAccessToken() {
    return this._user.access_token;
  }
}
